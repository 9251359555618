<template>
  <el-dialog
    title="Thêm mới khối học"
    :visible.sync="dialogVisible"
    width="600px"
    :before-close="closeDialog"
    :close-on-click-modal="false"
  >
    <el-form
      label-width="120px"
      :model="dataInput"
      label-position="left"
      :rules="rules"
      ref="dataInput"
    >
      <el-form-item label="Tên khối học" prop="gradeName">
        <el-input
          v-model="dataInput.gradeName"
          @keyup.enter.native="submitForm('dataInput')"
          placeholder="Nhập tên khối"
        ></el-input>
        <span class="error-message">{{ customErrorMessages.newName }}</span>
      </el-form-item>
      <el-form-item label="Mô tả" prop="gradeDescription">
        <el-input
          :rows="1"
          type="textarea"
          autosize
          v-model="dataInput.gradeDescription"
          placeholder="Nhập mô tả"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button
          type="success"
          size="medium"
          :loading="loadingButton"
          mini
          v-if="showSaveChange"
          @click="submitForm()"
      >
        <i class="el-icon-circle-check" />
        <span>{{ $t("button.save") }}</span>
      </el-button>
      <el-button type="danger" size="medium" @click="closeDialog()">
        <i class="el-icon-circle-close" />
        <span>{{ $t("button.close") }}</span>
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import GradeService from "@/services/GradeService";
import {mapActions, mapState} from "vuex";
export default {
  props: {
    dialogVisible: null,
  },
  computed:{
    ...mapState('gradeModule',['getAllGradeInSchoolList','gradeOfSchoolList'])
  },
  data() {
    return {
      dataInput: {
        gradeName: "",
        gradeDescription: "",
      },
      customErrorMessages: {
        newName: ""
      },
      showSaveChange: false,
      loadingButton: false,
      rules: {
        gradeName: [
          {
            validator: this.changeGradeName,
            trigger: "change"
          },
        ],
      },
    };
  },
  methods: {
    ...mapActions('gradeModule',['fetchDataGetAllGradeInSchoolList']),
    //reset when click x
    closeDialog() {
      this.showSaveChange = false
      this.customErrorMessages.newName = ""; // Xóa thông báo lỗi tùy chỉnh
      this.$emit("dialog-close");
      setTimeout(() => {
        this.$refs["dataInput"].resetFields();
      }, 300);
    },

    async changeGradeName(rule, value, callback){
      if (this.getAllGradeInSchoolList.length !== 0 ){
        for (let i = 0; i < this.getAllGradeInSchoolList.length ; i++) {
          if (!value) {// Kiểm tra nếu giá trị rỗng
            this.customErrorMessages.newName = "Giá trị không được để trống!";
            this.showSaveChange = false
            break
          }else  if (value.toLowerCase().replace(/\s/g, "") === this.getAllGradeInSchoolList[i].gradeName.toLowerCase().replace(/\s/g, "")){
            this.customErrorMessages.newName = "Tên khối: "+this.getAllGradeInSchoolList[i].gradeName + " đã tồn tại!";
            this.showSaveChange = false
            break
          }else {
            this.showSaveChange = true
            this.customErrorMessages.newName = ""; // Xóa thông báo lỗi tùy chỉnh
            await callback();
          }
        }
      }else {
        this.showSaveChange = true
        this.customErrorMessages.newName = ""; // Xóa thông báo lỗi tùy chỉnh
        await callback();
      }
    },
    submitForm() {
      this.$refs["dataInput"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          GradeService.create(this.dataInput)
            .then((resp) => {
              this.fetchDataGetAllGradeInSchoolList()
              this.$message({
                message: resp.data.message,
                type: "success",
              });
              this.closeDialog();
            })
            .catch((err) => {
              this.$message({
                message: err.response.data.message,
                type: "error",
              });
            }).finally(()=>{
            this.loadingButton = false;
          })

        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-input__inner,
/deep/.el-textarea__inner {
  border: none;
  border-radius: 0;
  resize: none;
  padding-left: 0;
  padding-right: 0;
  border-bottom: 1px solid #d9d9d9;
}

.error-message {
  color: red;
}
</style>
